<template>
    <mobile-admin-collapse-tab title="Événements" :badge="getBadgeOrNull('evenements')">
        <p v-if="!evenements.length">Aucune demande pour l'instant</p>
        <div v-for="(evenement, index) in evenements" :key="'evenement_' + evenement.id">
            <hr v-if="index"/>
            <p class="text-center">
                {{ evenement.nom }}
            </p>
            <p>Organisé par <user-name :user="evenement.organisateur"/></p>
            <p v-if="evenement.resume">
                <strong>Résumé : </strong>{{ evenement.resume }}
            </p>
            <p>
                {{ formatDates(evenement) }} <br/>
            </p>
            <p class="text-center">
                <b-button variant="success" pill @click="confirmEvenement(evenement, true)">
                    <font-awesome-icon icon="check"/>
                </b-button>
                <b-button variant="danger" pill @click="confirmEvenement(evenement, false)">
                    <font-awesome-icon icon="times"/>
                </b-button>
            </p>
        </div>
    </mobile-admin-collapse-tab>
</template>

<script>
    import {mapGetters} from 'vuex';

    import {apiPath}            from '@/util/http';
    import alert                from '@/util/alert';
    import demande              from '@/util/demande';
    import {updateBadgesBureau} from '@/util/badge';
    import reservationsMixin    from '@/mixin/reservationsMixin';


    const UserName               = () => import('@/components/UserName');
    const CommentValidationModal = () => import('@/components/modals/CommentValidationModal');
    const MobileAdminCollapseTab = () => import('./MobileAdminCollapseTab');

    export default {
        name: "MobileAdminLocalTab",
        components: {UserName, MobileAdminCollapseTab},
        data: () => ({
            evenements: []
        }),
        computed: {
            ...mapGetters({getBadgeOrNull: ['badge/getBadgeOrNull']})
        },
        methods: {
            ...demande,
            loadData() {
                alert.loading()
                    .then(() => this.axios.get(apiPath('list_waiting_evenements_admin')))
                    .then(response => this.evenements = demande.momentDates(response.data))
                    .catch(() => this.$toaster.error('Impossible de récupérer la liste des événements'))
                    .finally(alert.stopLoading);
            },
            confirmEvenement(emprunt, valid) {
                this.$store.dispatch('modal/create', {
                    component: CommentValidationModal,
                    props: {
                        title: (valid ? 'Validation' : 'Refus') + " de l'événement",
                        callback: (commentaire, notifyDiscord) => this.axios
                            .post(
                                apiPath('confirm_evenement_admin', {evenement: emprunt.id}),
                                {valid: valid, commentaire: commentaire, notify_discord: notifyDiscord}
                            )
                            .then(() => {
                                this.$toaster.success('Événement ' + (valid ? 'validé' : 'refusé') + ' avec succès');
                                this.loadData();
                                updateBadgesBureau();
                            })
                            .catch(() => this.$toaster.error("Impossible de modifier l'événement"))
                    }
                });
            },
        },
        mounted() {
            this.loadData();
        }
    }
</script>

<style scoped>
    em {
        text-decoration: underline;
    }
</style>